import './App.css';
import {TwitchEmbed} from "react-twitch-embed";
import {TwitterTimelineEmbed} from 'react-twitter-embed';
import Feed from "react-instagram-authless-feed"
import * as React from "react";
import YouTube from "react-youtube";

const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });


class LatestYoutube extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: null,
            opts: {
                width: "100%",
                height: "180px",
                playerVars: {
                    rel: 0,
                    controls: 0,
                    showinfo: 0
                }
            }
        }
    }

    async componentDidMount() {
        const youtube_query_url = "https://www.googleapis.com/youtube/v3/search?order=date&part=snippet&channelId=UCIw4aeZpgRqj9ZZlYcmVhcA&key=AIzaSyB3KrUz_gJvbFFt5ipGwMralSjanay72qw";
        const response = await fetch(youtube_query_url);
        const data = await response.json();
        this.setState({data: data.items[0], loading: false})
    }

    render() {
        return (
            <>
                {this.state.loading ? (
                    <>
                        <h2>Loading...</h2>
                        <p><small>If loading for a long time, try refreshing the page. If the issue persists, Youtube
                            might be down.</small></p>
                    </>
                ) : (
                    <>
                        <p><strong>{renderHTML(this.state.data["snippet"]["title"])}</strong></p><br/>
                        <YouTube videoId={this.state.data["id"]["videoId"]} opts={this.state.opts}
                                 onReady={this._onReady}/>
                    </>
                )}
            </>
        )
    }
}

const Stream = () => {
    return (
        <div>
            <TwitchEmbed
                channel="hayz"
                id="hayz"
                theme="dark"
                width="100%"
                muted
                onVideoPause={() => console.log(':(')}
            />
        </div>
    );
}

const LatestTweet = () => {
    return (
        <div>
            <TwitterTimelineEmbed
                sourceType="profile"
                screenName="HayzStreams"
                theme="dark"
                options={{tweetLimit: "1", width: "100%", height: 400}}
                noHeader={true}
                noBorders={true}
                noFooter={true}
                noScroll={true}
                transparent={true}
                limit="1"
            />
        </div>
    )
}

class LatestInstagram extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        }
    }

    async componentDidMount() {
        const url = "https://www.instagram.com/";
        const response = await fetch(url);
        if (response.status === 200) {
            this.setState({loading: false})
        }
    }

    render() {
        return (
            <>
                {this.state.loading ? (
                    <>
                        <h2>Loading...</h2>
                        <p><small>If loading for a long time, try refreshing the page. If the issue persists, Instagram
                            might be down.</small></p>
                    </>
                ) : (
                    <>
                        <Feed userName="hayzstreams" className="Feed" classNameLoading="Loading" limit="1"/>
                    </>
                )}
            </>
        )
    }
}

function App() {
    return (
        <main>
            <div className="twitch-player">
                <Stream/>
            </div>

            <div className="grid">
                <div className="boxed">
                    <h3>UK Content Creator - Partnered with <a href="https://www.twitch.tv/" target="_blank"
                                                               rel="noreferrer">Twitch</a>
                        &nbsp;and <a href="https://sneakenergy.com/" target="_blank" rel="noreferrer">Sneak Energy</a>.
                    </h3>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                        the
                        industry's
                        standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
                        scrambled it
                        to make
                        a type specimen book. It has survived not only five centuries, but also the leap into electronic
                        typesetting,
                        remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset
                        sheets
                        containing
                        Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker
                        including
                        versions
                        of Lorem Ipsum.
                    </p>
                </div>
            </div>

            <div className="grid three-col">
                <div className="boxed">
                    <h3>Latest <span className="highlight">Tweet</span></h3>
                    <LatestTweet/>
                </div>
                <div className="boxed">
                    <h3>Latest on <span className="highlight">Youtube</span></h3>
                    <LatestYoutube/>
                </div>
                <div className="boxed">
                    <h3>Latest from <span className="highlight">Instagram</span></h3>
                    <LatestInstagram/>
                </div>
            </div>
        </main>
    );
}

export default App;
